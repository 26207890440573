import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"

import { OrderPaymentFail } from "page_components/checkout_summary"

const OrderFail = ({ data }) => {
  return (
    <Layout
      mainBackground={
        data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
      }
    >
      <Breadcrumbs title="Problem z zamówieniem" />
      <OrderPaymentFail />
    </Layout>
  )
}

export const Head = () => <Seo title="Problem z zamówieniem" noIndex />

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzI0MA==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default OrderFail
